<template>
  <div class="setting_main_bg">
    <div class="flexAC" v-if="roleBtnArray[0].power">
      <div class="iconfont iconjia settingAdd" @click="handleUserAdd"><span>新增</span></div>
    </div>
    <div class="flexAC" :class="roleBtnArray[0].power === true?'search_top':''">
      <el-input v-model="cont"  placeholder="请输入搜索内容" class="contInput"></el-input>
      <el-button class="settingSearch" @click="handleSearch">搜索</el-button>
      <el-button class="settingReset" @click="handleReset">重置</el-button>
    </div>
    <div class="user_table">
      <el-table
        stripe
        :data="userList"
        style="width: 100%">
        <el-table-column
          label="序号"
          type="index">
        </el-table-column>
        <el-table-column
          label="账号"
          prop="account">
        </el-table-column>
        <el-table-column
          label="姓名"
          prop="username">
        </el-table-column>
        <el-table-column
          label="电话"
          show-overflow-tooltip
          prop="userphone">
        </el-table-column>
        <el-table-column
          label="角色"
          prop="rolename">
        </el-table-column>
        <el-table-column
          label="单位名称"
          show-overflow-tooltip
          prop="companyname">
        </el-table-column>
        <el-table-column
          label="入网时间"
          show-overflow-tooltip
          prop="creatime">
        </el-table-column>
        <el-table-column
          label="签名"
          prop="signature">
          <template slot-scope="scope">
            <span v-if="scope.row.signature !== '' ">已上传</span>
            <span v-if="scope.row.signature === ''">未上传</span>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="180">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleEdit(scope.row)" v-if="roleBtnArray[2].power">编辑</el-button>
            <el-button type="text" size="small" @click="handleResetPwd(scope.row)" v-if="roleBtnArray[3].power">重置密码</el-button>
            <el-button type="text" size="small" @click="handleUse(scope.row)">
              <span v-if="scope.row.userstate == true">停用</span>
              <span v-if="scope.row.userstate == false">启用</span>
            </el-button>
            <el-button type="text" size="small" style="color:red;" @click="handleDelete(scope.row)" v-if="roleBtnArray[1].power">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @current-change="PageChange"
      :current-page="page"
      :page-sizes="[size]"
      :page-size="size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      class="settingPage">
    </el-pagination>

    <!-- 用户新增弹框 -->
    <el-dialog title="用户新增" :visible.sync="addFromShow" width="900px" center class="gyDialog" :close-on-click-modal="false" :before-close="addFromCancel">
      <el-form :model="addfrom" :rules="formRules" ref="addFrom">
        <div class="flexCB">
          <el-form-item label="单位名称" class="foItem" prop="companyid">
            <el-select v-model="addfrom.companyid" placeholder="请选择单位" :disabled="isSuper" class="adSe" style="width: 100%">
              <el-option v-for="item in companyList" :key="item.index"
                         :label="item.companyname"
                         :value="item.companyid">
                {{ item.companyname }}</el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="用户账号" class="foItem" prop="account">
            <el-input v-model="addfrom.account"  placeholder="请输入账号"></el-input>
          </el-form-item>
        </div>
        <div class="flexCB">
          <el-form-item label="用户姓名" class="foItem" prop="username">
            <el-input v-model="addfrom.username"  placeholder="请输入账号"></el-input>
          </el-form-item>
          <el-form-item label="用户电话" class="foItem" prop="userphone">
            <el-input v-model="addfrom.userphone"  placeholder="请输入用户电话"></el-input>
          </el-form-item>
        </div>
        <div class="flexCB">
          <el-form-item label="角色" class="foItem" prop="roleid">
            <el-select v-model="addfrom.roleid" placeholder="请选择角色" class="adSe" style="width: 100%" @change="roleChange">
              <el-option v-for="item in roleList" :key="item.index"
                         :label="item.rolename"
                         :value="item.roleid">
                {{ item.rolename }}</el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="roleCusShow" class="foItem" label="监管地区"  prop="permissions">
            <el-select v-model="addfrom.permissions" class="adSe" style="width: 100%"  multiple placeholder="请选择监管地区">
              <el-option v-for="item in beatOption"
                         :key="item.index"
                         :label="item.couname"
                         :value="item.couname">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="foItem">
          <label class="from_label">用户签名</label>
          <Upload :fileType="'jpg/png/jpeg'" :refresh="refresh" @uploadSuccess="addImgSuccess"></Upload>
          <p class="img_tip">支持.jpg.png.jpeg格式</p>
        </div>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="addFromCancel">取消</div>
        <div class="settingConfirm" @click="AddFromConfirm">确定</div>
      </div>
    </el-dialog>

    <!-- 用户编辑 -->
    <el-dialog title="用户编辑" :visible.sync="editFromShow" width="900px" center class="gyDialog unit_addDialog" :close-on-click-modal="false" :before-close="editFromCancel">
      <el-form :model="editfrom" :rules="editformRules" ref="editFrom">
        <div class="flexCB">
          <el-form-item label="单位名称" class="foItem" prop="companyid">
            <el-select v-model="editfrom.companyid" placeholder="请选择单位" class="adSe" style="width: 100%" disabled>
              <el-option v-for="item in companyList" :key="item.index"
                         :label="item.companyname"
                         :value="item.companyid">
                {{ item.companyname }}</el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="用户账号" class="foItem" prop="account">
            <el-input v-model="editfrom.account"  placeholder="请输入账号"></el-input>
          </el-form-item>
        </div>
        <div class="flexCB">
          <el-form-item label="用户姓名" class="foItem" prop="username">
            <el-input v-model="editfrom.username"  placeholder="请输入姓名"></el-input>
          </el-form-item>
          <el-form-item label="用户电话" class="foItem" prop="userphone">
            <el-input v-model="editfrom.userphone"  placeholder="请输入用户电话"></el-input>
          </el-form-item>
        </div>
        <div class="flexCB">
          <el-form-item label="角色" class="foItem" prop="roleid">
            <el-select v-model="editfrom.roleid" placeholder="请选择角色" disabled class="adSe" style="width: 100%" @change="roleChange">
              <el-option v-for="item in roleList" :key="item.index"
                         :label="item.rolename"
                         :value="item.roleid">
                {{ item.rolename }}</el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="roleCusShow" class="foItem" label="监管地区"  prop="permissions">
            <el-select v-model="customerBrr" class="adSe" style="width: 100%"  multiple placeholder="请选择监管地区">
              <el-option v-for="(item, index) in beatOption"
                         :key="index"
                         :label="item.couname"
                         :value="item.couname">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="foItem">
          <label class="from_label">用户签名</label>
          <Upload :fileType="'jpg/png/jpeg'" :picture="editfrom.signature" @uploadSuccess="editImgSuccess"></Upload>
          <p class="img_tip">支持.jpg.png.jpeg格式</p>
        </div>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="editFromCancel">取消</div>
        <div class="settingConfirm" @click="editFromConfirm">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Upload from '@/components/upload.vue'
import { unitDownQuery } from '@/http/api/unit'
import { userQuery, roleQuery, userAdd, userEdit, userDelete, userPwdReset, userUse, region } from '@/http/api/user'
import { isValDateMobile } from '@/utils/validate.js'
export default {
  name: 'userSetting',
  props: ['roleBtn'],
  components: {
    Upload
  },
  data () {
    // const checkUserName = (rule, value, callback) => {
    //   this.userList.forEach(item => {
    //     if (item.account === value) {
    //       return callback(new Error('账号重复'))
    //     }
    //   })
    //   return callback()
    // }
    const UserMobile = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error('请输入用户电话'))
      }
      if (isValDateMobile(value)[0]) {
        callback(new Error(isValDateMobile(value)[1]))
      } else {
        callback()
      }
    }
    return {
      cont: '',
      page: 1,
      size: 10,
      total: 0,
      userList: [],
      companyid: '',
      companyList: [],
      roleList: [],
      refresh: false,
      addFromShow: false,
      addfrom: {
        account: '',
        username: '',
        userphone: '',
        companyid: '',
        roleid: '',
        signature: '',
        permissions: []
      },
      formRules: {
        companyid: [
          { required: true, trigger: 'blur', message: '请选择单位' }
        ],
        account: [
          { required: true, trigger: 'blur', message: '账号不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
          // { validator: checkUserName, message: '账号重复', trigger: 'blur' }
        ],
        username: [
          { required: true, trigger: 'blur', message: '姓名不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        userphone: [
          { required: true, validator: UserMobile, trigger: 'blur' }
        ],
        roleid: [
          { required: true, trigger: 'blur', message: '请选择角色' }
        ],
        permissions: [
          { required: true, trigger: 'blur', message: '请选择管辖区域' }
        ]
      },
      editFromShow: false,
      editfrom: {},
      customerBrr: [],
      editformRules: {
        // companyid: [
        //   { required: true, trigger: 'blur', message: '请选择单位' }
        // ],
        account: [
          { required: true, trigger: 'blur', message: '账号不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' },
          // { validator: checkUserName, message: '账号重复', trigger: 'blur' }
        ],
        username: [
          { required: true, trigger: 'blur', message: '姓名不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        userphone: [
          { required: true, validator: UserMobile, trigger: 'blur' }
        ],
        roleid: [
          { required: true, trigger: 'blur', message: '请选择角色' }
        ],
        permissions: [
          { required: true, trigger: 'blur', message: '请选择管辖区域' }
        ]
      },
      isSuper: true,
      roleBtnArray: [],
      roleCusShow: false,
      beatOption: []
    }
  },
  beforeMount () {
    if (this.roleBtn.length > 0) {
      this.roleBtnArray = this.roleBtn
    }
  },
  created () {
    this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    this.addfrom.companyid = this.companyid
    if (this.companyid === '') {
      this.isSuper = false
    } else { this.isSuper = true }
    this.DropDown() // 下拉选择框
    this.init()
  },
  methods: {
    DropDown () {
      // 单位选择下拉框
      unitDownQuery().then(res => {
        if (res.status === '1') {
          this.companyList = res.data
        } else {
          this.companyList = []
        }
      })
      // 角色选择下拉框
      const parmas = {
        rolename: '',
        roleid: '',
        companyid: this.companyid,
        page_index: 1,
        page_size: 1000
      }
      roleQuery(parmas).then(res => {
        if (res.status === '1') {
          this.roleList = res.data.data
        } else {
          this.roleList = []
        }
      })
    },
    init () {
      const parmas = {
        userid: '',
        username: this.cont,
        companyid: this.companyid,
        page_index: this.page,
        page_size: this.size
      }
      userQuery(parmas).then(res => {
        if (res.status === '1') {
          if (res.data.data !== undefined || res.data.data !== null) {
            this.userList = res.data.data
            this.total = res.data.recordcount
          } else {
            this.userList = []
            this.total = 0
          }
        } else {
          this.userList = []
          this.total = 0
        }
      })
    },
    PageChange (page) {
      this.page = page
      this.init()
    },
    handleSearch () {
      this.page = 1
      this.init()
    },
    handleReset () {
      this.page = 1
      this.cont = ''
      this.init()
    },
    roleChange (val) {
      if (val === '12') {
        this.roleCusShow = true
        this.$nextTick(() => {
          region().then(res => {
            this.beatOption = res.data
          })
        })
      } else {
        this.roleCusShow = false
      }
    },
    // 展示新增弹框
    handleUserAdd () {
      this.addFromShow = true
      this.addfrom.permissions = []
      this.roleCusShow = false
      this.refresh = !this.refresh
    },
    // 关闭新增弹框
    addFromCancel () {
      this.$refs.addFrom.resetFields()
      this.addFromShow = false
    },
    addImgSuccess (res) {
      this.addfrom.signature = res.filepath
    },
    // 新增
    AddFromConfirm () {
      this.$refs.addFrom.validate((valid) => {
        if (!valid) return
        const params = {
          account: this.addfrom.account,
          username: this.addfrom.username,
          userphone: this.addfrom.userphone,
          companyid: this.addfrom.companyid,
          roleid: this.addfrom.roleid,
          signature: this.addfrom.signature,
          permissions: this.addfrom.permissions
        }
        userAdd(params).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.addFromCancel()
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    // 展示编辑弹框
    handleEdit (row) {
      this.editFromShow = true
      this.editfrom = { ...row }
      this.customerBrr = row.permissions.split(',')
      if (this.editfrom.roleid === '12') {
        this.roleCusShow = true
        region().then(res => {
          this.beatOption = res.data
        })
      } else {
        this.roleCusShow = false
      }
    },
    // 关闭编辑弹框
    editFromCancel () {
      this.editFromShow = false
      this.$refs.editFrom.resetFields()
    },
    editImgSuccess (res) {
      this.editfrom.signature = res.filepath
    },
    // 编辑
    editFromConfirm () {
      this.$refs.editFrom.validate((valid) => {
        if (!valid) return
        const params = {
          account: this.editfrom.account,
          username: this.editfrom.username,
          userphone: this.editfrom.userphone,
          companyid: this.editfrom.companyid,
          roleid: this.editfrom.roleid,
          signature: this.editfrom.signature,
          userid: this.editfrom.userid,
          permissions: this.customerBrr
        }
        userEdit(params).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.editFromCancel()
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    // 重置密码
    handleResetPwd (row) {
      this.$confirm('重置密码后将恢复到初始密码“888888”，请确定是否重置?', '密码重置', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        userPwdReset({ userid: row.userid }).then(res => {
          this.$message.success(res.message)
          this.init()
        })
      }).catch(() => {})
    },
    // 停启用
    handleUse (row) {
      const params = { userid: row.userid }
      userUse(params).then(res => {
        if (res.status === '1') {
          this.init()
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 删除
    handleDelete (row) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        userDelete({ userid: row.userid }).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    }
  }
}
</script>

<style scoped>
.search_top,.user_table{
  margin-top: 20px;
}
.foItem{
  width: 390px;
}
</style>
